import {Collection} from '../lib'
import Session from './Session'


class Authors extends Collection {
    static get collectionName(){
        return 'authors'
    }
    static create(params){
        return super.create({
            ...params,
            accountID: Session.value.id
        })
    }
    static async fetch(){
        if(this.unfollow){
            this.unfollow()
        }
        this.unfollow = this.where({
            accountID: Session.value.id
        }).follow()

        if(!Session.currentAuthor.id && this.value.length !== 0){
            console.log('setting current author id', this.value[0])
            Session.setCurrentAuthorID(this.value[0].id)
        }
    }
    static default(){
        return []
    }
}

export default Authors