import axios from 'axios'
import {Session} from '../collections'
const api = {}

api.client = ()=>{
    const headers = {}
    if(Session.value.token) headers['x-token'] = Session.value.token
    let baseURL =  `${window.location.origin}/api/`
    if(window.location.origin.includes('localhost')){
        baseURL = window.location.origin.replace('3000', '3001')
    } 

    const api = axios.create({
        baseURL,
        headers
    })
    


    return api
}

api.accounts = {
    create: (params)=>(
        api.client().post('/accounts', params)
    ),
    all: ()=>(
        api.client().get('/accounts')
    )
}
api.session = {
    signUp: (params)=>(
        api.client().post('/sessions', params)
    ),
    login: (params)=>(
        api.client().put('/sessions', params)
    )
}
api.stories = {
    all: (params)=>(
        api.client().get('/stories', params)
    ),
    create: (author)=>(
        api.client().post('/stories', author)
    ),
    update: (author)=>(
        api.client().put('/stories', author)
    ),
    destroy: ()=>(
        api.client().delete('/stories')
    )
}
api.scenes = {
    all: (params)=>(
        api.client().get('/scenes', {params})
    ),
    create: (author)=>(
        api.client().post('/scenes', author)
    ),
    update: (author)=>(
        api.client().put('/scenes', author)
    ),
    destroy: ()=>(
        api.client().delete('/scenes')
    )
}
api.paragraphs = {
    all: (params)=>(
        api.client().get('/paragraphs', {params})
    ),
    create: (author)=>(
        api.client().post('/paragraphs', author)
    ),
    update: (params)=>(
        api.client().put(`/paragraphs/${params.id}`, params)
    ),
    destroy: ({id})=>(
        api.client().delete(`/paragraphs/${id}`)
    )
}
api.authors = {
    all: (params)=>(
        api.client().get('/authors', params)
    ),
    create: (author)=>(
        api.client().post('/authors', author)
    ),
    update: (author)=>(
        api.client().put('/authors', author)
    ),
    destroy: ()=>(
        api.client().delete('/authors')
    )
}
api.myAuthors = {
    all: (params)=>(
        api.client().get('/my-authors', params)
    )
}
window.api = api
export default api