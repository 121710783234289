import React, {Component} from 'react'
import {
    Input,
    Form,
    Button,
    Card
} from 'antd'
import {
    Stories,
    Session,
    Authors
} from '../../../collections'
import { connect } from 'react-redux'
import {MainLayout} from '../../partials'
import { Link } from 'react-router-dom'

class StoriesComponent extends Component {
    constructor(props){
        super(props)
        this.formRef = React.createRef()
        this.state = {}
        Stories.fetch()
        Authors.fetch()
        this.handleSelectAuthor = this.handleSelectAuthor.bind(this)
        this.handleStory = this.handleStory.bind(this)
    }
    get stories(){
        return this.props.stories || []
    }
    handleStory(story){
        Stories.create({
            ...story,
            authorID: Session.currentAuthor.id
        })
        this.formRef.current.resetFields()
    }
    handleSelectAuthor(author){
        if(author === 'new'){
            this.setState({
                authorCreateOrEdit: true
            })
        }
    }
    get authors(){
        return this.props.authors || []
    }
    render(){
        return (
            <MainLayout>
                <Card title={'Your New Story'} style={{marginTop: '1em'}}>
                    <Form ref={this.formRef} onFinish={this.handleStory}>
                        <Form.Item
                            name='title'
                        >
                            <Input placeholder='Name of story' />
                        </Form.Item>
                        <Form.Item
                            name='description'
                        >
                            <Input.TextArea placeholder='Description of story' />
                        </Form.Item>
                        <Button htmlType='submit'>Save</Button>
                    </Form>
                </Card>
                {this.stories.map((story, i)=>(
                    <div style={{marginTop: '1em'}} key={`stories-${i}`}>
                        <Link to={`/stories/${story.id}`}>
                            <Card title={story.title}>
                                {story.description}
                            </Card>
                        </Link>
                    </div>
                ))}
            </MainLayout>
        )
    }
}

export default connect((state)=> ({
    stories: state.stories,
    authors: state.authors
}))(StoriesComponent)