import React from 'react'
import * as collections from '../../collections'
import { Link } from 'react-router-dom'
export default (props)=>{
    const handleRemove = (e)=>{
        e.preventDefault()

        collections.Paragraphs.destroy(props.paragraph.id)
    }
    return (<>
            <Link href='#' onClick={handleRemove} style={{display: 'block'}}>
                Remove
            </Link>
            <br />
            <Link onClick={props.handleEdit}>Edit</Link>
        </>
    )
}