import React from 'react'
import {connect} from 'react-redux'
import {withRouter, Route, Switch} from 'react-router-dom'
import {SignUp, Login, MainLayout} from '../../partials'
import {Row, Col} from 'antd'

const Component = (props)=>(
    <MainLayout>
        <Row>
            <Col xs={0} md={8}>
            </Col>
            <Col xs={24} md={8}>
                <Switch>
                    <Route path='/signup'>
                        <SignUp />
                    </Route>
                    <Route path='/login'>
                        <Login />
                    </Route>
                    <Route path='/'>
                        <Login />
                    </Route>
                </Switch>
            </Col>
            <Col xs={0} md={8}>
                
            </Col>
        </Row>
    </MainLayout>
)

export default withRouter(
    connect((state)=>
        state
    )(Component)
)