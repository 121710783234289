import React from 'react';
import {Stories, Story, Scene} from '../../templates'
import {connect} from 'react-redux'
import {Switch, Route} from 'react-router-dom'

const index = (props) =>{
  return (
    <div>
      <Switch>
        <Route exact path='/stories/:id' component={Story} />
        <Route exact path='/stories/:storyID/scenes/:id' component={Scene} />
        <Route path='/stories' exact component={Stories} />
        <Route path='/' exact component={Stories} />
      </Switch>
    </div>
  );
}

export default connect((state)=>({
  session: state.session
}))(index);
