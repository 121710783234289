import {Collection} from '../lib'


class Accounts extends Collection {
    static get collectionName(){
        return 'accounts'
    }
    search(){

    }
    static default(){
        return []
    }
}

export default Accounts