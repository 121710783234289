import React, {Component} from 'react'
import {
    Input,
    Drawer,
    Form,
    Button
} from 'antd'
import {
    Authors,
    Session
} from '../../../collections'

class AuthorComponent extends Component {
    constructor(props){
        super(props)
        this.state = {}
        this.handleSave = this.handleSave.bind(this)
        this.formRef = React.createRef()
    }
    onClose(){
        Session.setAuthorCreateOrEdit(false)
    }
    handleSave(author){
        Authors.create(author)
    }
    render(){
        return <Drawer
            title="Author Create"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.props.visible}
        >
            <Form ref={this.formRef} onFinish={this.handleSave}>
                <Form.Item 
                    name='firstName'
                >
                    <Input placeholder='First Name'/>
                </Form.Item>
                <Form.Item 
                    name='lastName'
                >
                    <Input placeholder='Last Name' />
                </Form.Item>
                <Form.Item 
                    name='description'
                >
                    <Input.TextArea placeholder='Description' />
                </Form.Item>
                <Button htmlType='submit'>Save</Button>
            </Form>
        </Drawer>

    }
}

export default AuthorComponent