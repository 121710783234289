import { createStore } from "redux";
import firebase from './firebase'
import "firebase/firestore";

var db = firebase.firestore();

const store = createStore((state={}, {type, key, payload})=>{
    switch(type){
        case 'set':
            state = {
                ...state
            }
            try{ 
                if(payload){
                    state[key] = JSON.parse(
                        JSON.stringify(payload)
                    )
                }
            }catch(_error){
                console.log(_error, payload)
            }

            return state
        default:
            return state
    }
});


class Collection {
    constructor(){
        store.dispatch({
            type: 'set',
            key: this.collectionName,
            payload: this.initialValue
        })
    }
    static get store(){
        return store
    }
    static set(payload){
        this.store.dispatch({
            type: 'set',
            key: this.collectionName,
            payload
        })
    }
    static merge(payload){
        const value = this.value
        return this.set({
            ...value,
            ...payload
        })
    }

    static update(params){
        params.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
        
        return this.collection.doc(params.id).set(params)
    }
    static create(params){
        params.id = this.collection.doc().id
        params.createdAt = firebase.firestore.FieldValue.serverTimestamp()
        params.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
        params.archived = false
        console.log(params)
        return this.collection.doc(params.id).set(params)
    }
    static archive(id){
        this.collection.doc(`${id}/archived`).set(true)
    }
    static destroy(id){
        this.collection.doc(id).set({})
        return this.collection.doc("DC").delete()
    }

    static get(){
        return this.collection.get()
    }

    static where(...params){
        let query
        if(typeof params[0] == 'object'){
            query = this.collection
            const _params = params[0]
            for(let [key, value] of Object.entries(_params)){
                query = query.where(key, '==', value)
            }
        }else{
            query = this.collection.where(...params)
        }

        const collection = this
        if(query){
            query.constructor.prototype.follow = function(){
                return this.onSnapshot((snapshot)=>{
                    const data = []
                    snapshot.forEach((res)=>{
                        data.push({
                            ...res.data(),
                            id: res.id
                        })
                    })

                    collection.set(data);
                })
            }
            query.constructor.prototype.fetch = function(){
                new Promise((resolve, reject)=>{
                    this.get().then((snapshot)=>{
                        const data = []
                        snapshot.forEach((res)=>{
                            data.push({
                                ...res.data(),
                                id: res.id
                            })
                        })
    
                        resolve(data);
                    }).catch((e)=>{
                        reject(e)
                    })
                })
            }
            query.constructor.prototype.save = async function(){
                return collection.set(
                    await query.fetch()
                )
            }
        }
        
        return query
    }
    
    static get collection(){
        return db.collection(this.collectionName)
    }
    
    static get value(){
        return (this.store.getState()[this.collectionName] || this.default())
    }
};

window.store = store;

export {
    Collection,
    store
}
export default Collection