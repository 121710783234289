import React from 'react';
import "antd/dist/antd.css";
import './app.scss';

import {connect} from 'react-redux'
import Authorized from './Authorized';



const index = (props) =>{
  return (
    <div className="App">
        {props.session && props.session.id ? (

          <Authorized />
        ):(
          <div>Loading...</div>
        )}
    </div>
  );
}

export default connect((state)=>({
  session: state.session
}))(index);
