import React, {Component} from 'react'
import {
    Input,
    Form,
    Button,
    Card,
} from 'antd'
import {
    Scenes,
    Session,
    Authors
} from '../../../collections'
import { connect } from 'react-redux'
import {Siders, MainLayout} from '../../partials'
import { Link, withRouter } from 'react-router-dom'

class StoryComponent extends Component {
    constructor(props){
        super(props)
        this.formRef = React.createRef()
        this.state = {}
        Authors.fetch()
        Session.merge({
            currentStoryID: props.match.params.id
        }) 
        Scenes.fetch()
        this.handleSelectAuthor = this.handleSelectAuthor.bind(this)
        this.handleScene = this.handleScene.bind(this)
    }
    get scenes(){
        return this.props.scenes || []
    }
    handleScene(story){
        if(this.props.match.params.id){
            Scenes.create({
                ...story,
                storyID: this.props.match.params.id,
                authorID: Session.currentAuthor.id
            })
        }
    }
    handleSelectAuthor(author){
        if(author === 'new'){
            this.setState({
                authorCreateOrEdit: true
            })
        }
    }
    get authors(){
        return this.props.authors || []
    }
    render(){
        return (
            <MainLayout>
                {this.scenes.map((scene, i)=>(
                    <div  style={{marginTop: '1em'}} key={`scenes-${i}`}>
                        <Link to={`/stories/${this.props.match.params.id}/scenes/${scene.id}`}>
                            <Card title={scene.name}>
                                {scene.description}
                            </Card>
                        </Link>
                    </div>
                ))}
                <Card title={'Your New Scene'} style={{marginTop: '1em'}}>
                    <Form ref={this.formRef} onFinish={this.handleScene}>
                        <Form.Item
                            name='name'
                        >
                            <Input placeholder='Name of Scene' />
                        </Form.Item>
                        <Form.Item
                            name='description'
                        >
                            <Input.TextArea placeholder='Description of story' />
                        </Form.Item>
                        <Button htmlType='submit'>Save</Button>
                    </Form>
                </Card>
                <Siders.Author visible={this.state.authorCreateOrEdit} />
            </MainLayout>
        )
    }
}

export default withRouter(connect((state)=> ({
    stories: state.stories,
    scenes: state.scenes,
    authors: state.authors
}))(StoryComponent))