import {Collection} from '../lib'
import Authors from './Authors'
import Accounts from './Accounts'
import firebase from '../lib/firebase'

class Session extends Collection {
    static get collectionName(){
        return 'session'
    }
    static get value(){
        return super.value || {}
    }
    static setCurrentAuthorID(currentAuthorID){
        this.merge({
            currentAuthorID
        })
    }
    static setAuthorCreateOrEdit(value){
        this.merge({
            authorCreateOrEdit: value
        })
    }
    static get currentAuthor(){
        return Authors.value.find((author)=>(
            author.id === (this.value || {}).authorID
        )) || {}
    }
    static async update(data){
        super.set(data)
        try{
            await Accounts.update(data)
        }catch(error){
            console.log(error)
        }
    }
    static default(){
        return {}
    }
}




var provider = new firebase.auth.GoogleAuthProvider();


firebase.auth().onAuthStateChanged(function(result) {
    if(!result){
        firebase.auth().signInWithRedirect(provider);
    }else{
        console.log(result)
        // const profile = result.additionalUserInfo.profile
        const names = result.displayName.split(" ")
        Session.update({
            firstName: names[0],
            lastName: names[names.length - 1],
            email: result.email,
            picture: result.photoURL,
            id: result.uid
        })
    }
})

export default Session