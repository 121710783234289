import React, {Component} from 'react'
import {
    Input,
    Form,
    Popover,
} from 'antd'
import {
    MoreOutlined
} from '@ant-design/icons';
import {
    Stories,
    Scenes,
    Authors,
    Paragraphs,
    Session
} from '../../../collections'
import { connect } from 'react-redux'
import {ParagraphOptions} from '../../groups'
import {Siders, MainLayout} from '../../partials'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

class StoryComponent extends Component {
    constructor(props){
        super(props)
        this.formRef = React.createRef()
        this.editFormRef = React.createRef()
        this.state = {}
        Authors.fetch()
        Stories.fetch()
        this.editRef = React.createRef()
        this.handleSelectAuthor = this.handleSelectAuthor.bind(this)
        this.handleParagraph = this.handleParagraph.bind(this)
        this.handleParagraphKeyboard = this.handleParagraphKeyboard.bind(this)
        this.handleSelectScene = this.handleSelectScene.bind(this)
        this.handleSceneClose = this.handleSceneClose.bind(this)

        this.handleEdit = this.handleEdit.bind(this)
        this.handleUpdateParagraph = this.handleUpdateParagraph.bind(this)
        this.handleUpdateParagraphKeyboard = this.handleUpdateParagraphKeyboard.bind(this)
    }
    componentDidMount(){
        this.followScenes()
        this.followParagraphs()
    }
    followScenes(){
        if(this.props.match.params.storyID) {
            Session.merge({
                currentStoryID: this.props.match.params.storyID
            })
            console.log("Scenes here")
            Scenes.fetch()
        }
    }
    followParagraphs(){
        if(this.props.match.params.id) {
            Session.merge({
                currentSceneID: this.props.match.params.id
            })
            Paragraphs.fetch()
        }
    }
    componentDidUpdate(previousProps, previousState){
        if(this.props.match.params.storyID && previousProps.match.params.storyID && this.props.match.params.storyID !== previousProps.match.params.storyID){
            this.followScenes()
        }
        if(this.props.match.params.id && previousProps.match.params.id && this.props.match.params.id !== previousProps.match.params.id){
            this.followParagraphs()
        }
        if(Object(this.state.editing).id && Object(this.state.editing).id !== Object(previousState.editing).id){
            this.editRef.current.focus()
            setTimeout(()=>{
                this.editFormRef.current.setFieldsValue({
                    content: ''
                })
                this.editFormRef.current.setFieldsValue({
                    content: this.state.editing.content
                })
            }, 1)
        }
    }
    get paragraphs(){
        return _.sortBy(
            this.props.paragraphs || []
        , 'order')
    }
    async handleParagraph(paragraph){
        if(this.props.match.params.id){
            await Paragraphs.create(paragraph)
            this.formRef.current.setFieldsValue({content: ''})
        }
    }
    handleSelectAuthor(author){
        if(author === 'new'){
            this.setState({
                authorCreateOrEdit: true
            })
        }
    }
    get authors(){
        return this.props.authors || []
    }
    handleSelectScene(){
        this.setState({
            sceneSelectVisible: true
        })
    }
    handleSceneClose(){
        this.setState({
            sceneSelectVisible: false
        })
    }
    handleParagraphKeyboard(e){
        if(e.key ==='Enter' || e.keyCode == 13){
            e.preventDefault()
            this.formRef.current.submit()
        }
    }
    handleEdit(paragraph){
        this.setState({
            editing: paragraph
        })
    }
    async handleUpdateParagraph(paragraph){
        await Paragraphs.update({
            id: this.state.editing.id,
            ...paragraph
        })
        this.setState({
            editing: undefined
        })
    }
    handleUpdateParagraphKeyboard(e){
        if(e.key ==='Enter'){
            e.preventDefault()
            this.editFormRef.current.submit()
        }
    }
    isEditingParagraph(paragraph){
        return Object(this.state.editing).id === paragraph.id
    }
    render(){
        return (
            <MainLayout sceneSelectVisible>
                <div 
                    style={{
                        height:'100%',
                        display: 'inline-block',
                        marginTop: '80px',
                        position: 'fixed',
                        top:'20px',
                        left:'10px'
                    }}
                    onClick={this.handleSelectScene}
                >
                    s<br />
                    c<br />
                    e<br />
                    n<br />
                    e<br />
                    s
                </div>
                <Siders.SceneSelect 
                    visible={this.state.sceneSelectVisible} 
                    onClose={this.handleSceneClose}
                />
                <div style={{position: 'relative', marginTop: '40px'}}>
                    {this.paragraphs.map((paragraph, i)=>(
                        this.isEditingParagraph(paragraph) ? (
                            <Form
                                key={`paragraphs-${i}`}
                                ref={this.editFormRef}
                                initialValues={{content: paragraph.content}}
                                onFinish={this.handleUpdateParagraph}
                            >
                                <Form.Item
                                    name='content'
                                    noStyle
                                >
                                    <Input.TextArea 
                                        placeholder='Write your paragraph' 
                                        onKeyDown={this.handleUpdateParagraphKeyboard} 
                                        className={'paragraph'}
                                        autoSize={{ minRows: 1 }}
                                        ref={this.editRef}
                                    />
                                </Form.Item>
                            </Form>
                        ):(
                            <div className='paragraph' key={`paragraphs-${i}`}>
                                <div className='options'>
                                    <Popover placement="topLeft" title={'options'} content={
                                        <ParagraphOptions paragraph={paragraph} handleEdit={this.handleEdit.bind(null, paragraph)} />
                                    } trigger="click">
                                        <MoreOutlined />
                                    </Popover>
                                </div>
                                <div onClick={this.handleEdit.bind(null, paragraph)}>
                                    {paragraph.content}
                                </div>
                            </div>
                        )
                    ))}
                    <Form ref={this.formRef} onFinish={this.handleParagraph}>
                        <Form.Item
                            name='content'
                        >
                            <Input.TextArea 
                                placeholder='Write your paragraph' 
                                onKeyDown={this.handleParagraphKeyboard} 
                                className={'paragraph'}
                                autoSize
                            />
                        </Form.Item>
                    </Form>
                    <Siders.Author visible={this.state.authorCreateOrEdit} />
                    <br />
                </div>
            </MainLayout>
        )
    }
}

export default withRouter(connect((state)=> ({
    stories: state.stories,
    scenes: state.scenes,
    paragraphs: state.paragraphs,
    authors: state.authors
}))(StoryComponent))