import {Collection} from '../lib'
import Session from './Session'


class Scenes extends Collection {
    static get collectionName(){
        return 'scenes'
    }
    static create(params){
        if(Session.value.currentStoryID && Session.value.currentAuthorID){
            params.storyID=Session.value.currentStoryID
            params.authorID=Session.value.currentAuthorID
            return super.create(params)
        }
    }
    static fetch(){
        if(Session.value.currentStoryID){
            if(this.unfollow){
                this.unfollow()
            }
            this.unfollow = this.where({
                storyID: Session.value.currentStoryID
            }).orderBy('createdAt').follow()
        }
    }
    static default(){
        return []
    }
}

export default Scenes