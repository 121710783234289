import React, {Component} from 'react'
import {
    Drawer
} from 'antd'
import { Link, withRouter } from 'react-router-dom'
import {connect} from 'react-redux'
import _ from 'lodash'

class SceneSelect extends Component {
    constructor(props){
        super(props)
        this.state = {}
        this.onClose = this.onClose.bind(this)
    }
    onClose(){
        this.props.onClose()
    }
    render(){
        return <Drawer
            title={"Scenes"}
            placement="left"
            closable={false}
            onClose={this.onClose}
            visible={this.props.visible}
            style={{top: '65px', zIndex: 0}}
        >
            {_.get(this,'props.scenes', []).map((scene, i)=>
                <div key={`scenes-${i}`}>
                    <Link to={`/stories/${this.props.match.params.storyID}/scenes/${scene.id}`} onClick={this.onClose}>
                        {i + 1} {scene.name}
                    </Link>
                </div>
            )}
        </Drawer>
    }
}

export default withRouter(connect((state)=>({scenes: state.scenes}))(SceneSelect))