import {Collection} from '../lib'
import Session from './Session'

class Paragraphs extends Collection {
    static get collectionName(){
        return 'paragraphs'
    }
    static create(params){
        if(Session.value.currentSceneID){
            params.sceneID = Session.value.currentSceneID
            params.authorID = Session.value.currentAuthorID
            params.order = (this.value || []).length + 1
            return super.create(params)
        }
    }
    static update(params){
        const paragraph = this.value.find(({id})=>(
            params.id===id
        ))
            
        super.update({
            ...paragraph,
            ...params
        })
    }
    static destroy(id){
        return super.destroy(id)
    }
    static fetch(){
        if(Session.value.currentSceneID){
            if(this.unfollow){
                this.unfollow()
            }
            this.unfollow = this.where({
                sceneID: Session.value.currentSceneID
            }).orderBy('createdAt', 'asc').follow()
        }
    }
    static default(){
        return []
    }
}

export default Paragraphs