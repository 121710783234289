import React, {Component} from 'react'
import {Session, Authors} from '../../collections'
import {connect} from 'react-redux'
import {Select} from 'antd'


class CurrentAuthor extends Component{
    constructor(props){
        super(props)
        
        this.handleSelectAuthor = this.handleSelectAuthor.bind(this)
    }
    componentDidMount(){
        Authors.fetch()
    }
    handleSelectAuthor(authorID){
        if(authorID === 'new'){
            Session.setAuthorCreateOrEdit(true)
        }else{
            Session.setCurrentAuthorID(authorID)
        }
    }
    render(){
        return (
            <Select
                showSearch
                placeholder="Select an Author"
                optionFilterProp="children"
                onChange={this.handleSelectAuthor}
                value={this.props.store.session.currentAuthorID}
                style={{marginTop: '1px'}}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                <Select.Option value="new">New</Select.Option>
                {this.props.store.authors.map((author, i)=>(
                    <Select.Option key={i} value={author.id}>{author.firstName} {author.lastName}</Select.Option>
                ))}
            </Select>
        )
    }
}

export default connect(({authors,session})=>({
    store: {
        authors: authors || [],
        session: session || {}
    }
}))(CurrentAuthor)