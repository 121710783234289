import React, {Component} from 'react'
import {
    Input,
    Col,
    Row,
    Button,
    Form
} from 'antd'
import {Session} from '../../../collections'
import { Link } from 'react-router-dom'

class SignUp extends Component {
    constructor(props){
        super(props)
        this.state = {}
        this.handleLogin = this.handleLogin.bind(this)
        this.formRef = React.createRef()
    }
    hasNumber(password){
        return password && password.match(/[0-9]/g)
    }
    hasUpperCase(password){
        return password && password.match(/[A-Z]/g)

    }
    hasLowerCase(password){
        return password && password.match(/[a-z]/g)
    }
    testPasswordMatch(password, passwordConfirmation){
        let passwordsMatch
        if(password && password === passwordConfirmation){
            passwordsMatch = true;
        }else{
            passwordsMatch = false;
        }
        this.setState({
            passwordsMatch
        })
    }
    testPassword(password){
        let passwordHasNumber;
        let passwordHasUpperCase;
        let passwordHasLowerCase;
        if(this.hasNumber(password)){
            passwordHasNumber = true
        }else{
            passwordHasNumber = false
        }
        if(this.hasUpperCase(password)){
            passwordHasUpperCase = true
        }else{
            passwordHasUpperCase = false
        }
        if(this.hasLowerCase(password)){
            passwordHasLowerCase = true
        }else{
            passwordHasLowerCase = false
        }
        
        this.setState({
            passwordHasLowerCase,
            passwordHasUpperCase,
            passwordHasNumber
        })
        const passwordTest = (
            passwordHasLowerCase &&
            passwordHasUpperCase &&
            passwordHasNumber
        )
        if(passwordTest){
            return true
        }else{
            return false
        }
    }
    handleLogin(values){
        Session.login(values);
    }
    render(){
        return (
            <div>
                <Row>
                    <Col xs={24} md={12}>
                        <Form ref={this.formRef} onFinish={this.handleLogin}>
                            <Row>
                                <Col xs={24} style={{padding: 12}}>
                                    <Form.Item
                                        name='email'
                                    >
                                        <Input placeholder='johndoe@example.com' />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} style={{padding: 12}}>
                                    <Form.Item 
                                        name='password'
                                    >
                                        <Input
                                            type='password'
                                            placeholder='yourpassword' 
                                            onKeyUp={this.handleTestPassword}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24}>
                                    <Button htmlType='submit'>
                                        Login
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={24} md={12}>
                        <Link to='/signup'>Signup</Link>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default SignUp