import React, {Component} from 'react'
import {withRouter, Link} from 'react-router-dom'
import {Menu, Layout, Breadcrumb } from 'antd'
import { CurrentAuthor, Siders } from '../partials'
import {connect} from 'react-redux'
const { Header, Content, Footer } = Layout;


class MainLayout extends Component{
    constructor(){
        super()
        this.state = {}
    }
    render(){
        return (
            <Layout>
                <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <div className="logo">
                    <Link to={'/'}>Epic</Link>
                </div>
                <Menu mode="horizontal" value={0}>
                        {this.props.sceneSelectVisible && (
                            <Menu.Item key="scenes">
                                <Link to={`/stories/${this.props.match.params.storyID}/scenes`}>scenes</Link>
                            </Menu.Item>
                        )}
                        {this.props.store.session && (
                            <Menu.Item style={{float: 'right'}}>
                                <CurrentAuthor />
                            </Menu.Item>
                        )}
                </Menu>
                </Header>
                <Content className="site-layout" style={{ padding: '0 25px', marginTop: 64 }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                    </Breadcrumb>
                    {this.props.children}
                </Content>
                <Siders.Author visible={Object(this.props.store.session).authorCreateOrEdit} />
                <Footer>The Footer</Footer>
            </Layout>
        )
    }
}

export default withRouter(
    connect((state)=> ({
        store: {
            session: state.session
        }
    }))(MainLayout)
)