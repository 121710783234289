import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyCmvsL9JfmurNWpKMehTuwzBWE7vgIpads",
    authDomain: "epic-e6165.firebaseapp.com",
    databaseURL: "https://epic-e6165.firebaseio.com",
    projectId: "epic-e6165",
    storageBucket: "epic-e6165.appspot.com",
    messagingSenderId: "843039797540",
    appId: "1:843039797540:web:31c6956e64ac0d0fbfc3b1",
    measurementId: "G-K3KYPW6ZGZ"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase