import {Collection} from '../lib'
import Session from './Session'

class Stories extends Collection {
    static get collectionName(){
        return 'stories'
    }
    static async create(params){
        params.accountID = Session.value.id
        params.authorID = Session.value.currentAuthorID
        
        super.create(params)
    }
    static fetch(params){
        if(Session.currentAuthor.id){
            if(this.unfollow){
                this.unfollow()
            }
            this.unfollow = this.where({
                accountID: Session.value.id,
                authorID: Session.currentAuthor.id
            }).orderBy('createdAt', 'desc').follow()
        }else{
            if(this.unfollow){
                this.unfollow()
            }
            this.unfollow = this.where({
                accountID: Session.value.id
            }).orderBy('createdAt', 'desc').follow()
        }
    }
    static default(){
        return []
    }
}

export default Stories