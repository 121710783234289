import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Collection } from './lib'
import { Provider } from "react-redux";
import * as collections from './collections'
import * as apiClient from './apiClient'
import Index from './components/yields/index';
import { BrowserRouter as Router} from 'react-router-dom'


window.app = {
    apiClient,
    collections
}

  
class Main extends Component{
    render (){

        return <div>
            <Provider store={Collection.store}>
                <Router>
                    <Index />
                </Router>
            </Provider>
        </div>
    }
}

ReactDOM.render((
    <Main />
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
